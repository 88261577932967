<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { getWxNavigator, emoji_filter } from "@/utils/utils.js";
import { getWeChartInfo } from "@/api/api.js";
import { Toast } from "vant";

export default {
  components: {
  },
  data() {
    return {
      code: "",
    };
  },
  mounted() {
    window.localStorage.setItem('scanUrl', location.href.split('#')[0])
    // 获取appid
    let that = this
    that.AppId = this.WX_ID;
    if (getWxNavigator()) {
      this.getCode();
    } else {
      //测试用openid
      localStorage.setItem("openId", "o3YA_6f-3W35uWAMpCaMRoaLTcSM");
    }
  },
  methods: {
    getCode() {
      // 非静默授权，第一次有弹框
      var local = window.location.href; // 获取页面url
      var appid = this.WX_ID;
      this.code = this.getUrlCode().code; // 截取code
      if (this.code == null || this.code === "" || this.code == undefined) {
        // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
      } else {
        if (this.code == null || this.code === "" || this.code == undefined) {
          Toast('未获取到code')
          // return
        }

        if (this.code !== localStorage.getItem("code")) {
          // 获取微信用户信息
          getWeChartInfo({
            code: this.code,
            appid: this.WX_ID,
            appsecret: this.WX_SECRET
          }).then((res) => {
            if (res.data.code == 0) {
              //设置头像
              localStorage.setItem("headImgUrl", res.data.data.headImgUrl);
              //设置设置名称
              localStorage.setItem("nickname", emoji_filter(res.data.data.nickname));
              //设置openid
              localStorage.setItem("openId", res.data.data.openId);
              localStorage.setItem("code", this.code)

            } else {
              Toast(res.data.msg)
            }
          })
        }
      }
    },
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
  },
};
</script>

<style lang="stylus">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
