import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    // 首页
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    // 我的
    path: '/Persion',
    name: 'Persion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Persion.vue')
  },
  {
    // 签到页
    path: '/sign',
    name: 'sign',
    component: () => import('../views/homeDetail/sign.vue')
  },
  {
    // 留言页
    path: '/leavingMessage',
    name: 'leavingMessage',
    component: () => import('../views/homeDetail/leavingMessage.vue')
  },
  {
    // 答题页
    path: '/answer',
    name: 'answer',
    component: () => import('../views/homeDetail/answer.vue')
  },
  {
    // 答题详情页
    path: '/answerDetail',
    name: 'answerDetail',
    component: () => import('../views/homeDetail/answerDetail.vue')
  },
  {
    // 投票页
    path: '/vote',
    name: 'vote',
    component: () => import('../views/homeDetail/vote.vue')
  },
  {
    // 投票详情页
    path: '/voteDetail',
    name: 'voteDetail',
    component: () => import('../views/homeDetail/voteDetail.vue')
  },
  {
    // 课程签到页
    path: '/courseSign',
    name: 'courseSign',
    component: () => import('../views/homeDetail/courseSign.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
