import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    LoginToken:"0",
  },
  mutations: {
    //设置登录token
    SET_LOGIN_TOKEN:(state,data)=>{
      state.LoginToken = data
    }
  },
  actions: {
  },
  modules: {
  }
})
