import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';

import FastClick from 'fastclick'
import 'lib-flexible'

Vue.config.productionTip = false
Vue.use(Vant);

Vue.prototype.WX_ID = process.env.VUE_APP_WX_ID
Vue.prototype.WX_SECRET = process.env.VUE_APP_WX_SECRET
Vue.prototype.WX_BASE_URL = process.env.VUE_APP_WX_BASE_URL

window.addEventListener(
  function () {
    FastClick.attach(document.body)
  },
  { passive: false }
)

router.beforeEach((to, from, next) => {
  /*路由发生改变修改页面的title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
