<template>
  <div class="home">
    <div class="body">
      <!-- 最顶部的大图 -->
      <div class="top-img">
        <img src="@/assets/img/banner.png" alt="" />
        <div class="h-welcome">欢迎进入</div>
        <div class="h-w-title">智慧党建活动室</div>
      </div>
      <!-- 小标题  “智慧互动” -->
      <div class="wisdom">
        <img src="@/assets/img/message.png" alt="" />
        <div class="wisdom-title">智慧互动</div>
      </div>

      <div class="container">
        <!-- 签到 -->
        <div class="box" @click="goSign()">
          <img class="font-img" src="@/assets/img/sign.png" alt="" />
          <div class="box-title">签到</div>
          <img class="back-img" src="@/assets/img/right.png" alt="" />
        </div>
        <!-- 答题 -->
        <div class="box" @click="goAnswer()">
          <img class="font-img" src="@/assets/img/answer.png" alt="" />
          <div class="box-title">答题</div>
          <img class="back-img" src="@/assets/img/right.png" alt="" />
        </div>
        <!-- 投票 -->
        <div class="box" @click="goVote()">
          <img class="font-img" src="@/assets/img/vote.png" alt="" />
          <div class="box-title">投票</div>
          <img class="back-img" src="@/assets/img/right.png" alt="" />
        </div>
        <!-- 留言墙 -->
        <div class="box" @click="goMessageWall()">
          <img class="font-img" src="@/assets/img/message-wall.png" alt="" />
          <div class="box-title">留言墙</div>
          <img class="back-img" src="@/assets/img/right.png" alt="" />
        </div>
      </div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import tabbar from "@/components/tabBar.vue";
import { Toast } from 'vant';

export default {
  name: "Home",
  components: {
    tabbar,
  },
  data() {
    return {
      AppId: 0,
      testToken:
        "eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoi5a2Q6LSm5Y-3IiwidG9rZW5fdHlwZSI6InRva2VuIiwidXNlcmlkIjoiOTE1NDE1MjQxOTc2NzA5MTciLCJhY2NvdW50IjoiYWRtaW4iLCJpYXQiOjE2MjMyMDQ5MDQsIm5iZiI6MTYyMzIwNDkwNCwiZXhwIjoxNjIzMjMzNzA0fQ.HcV6z8Vti1VLsImnWzhz4seCV6ORaK_AvpA9UZ3VCAo",
    };
  },
  mounted() {
  },
  methods: {
    // 设置token(测试)
    setToken() {
      this.$store.commit("SET_LOGIN_TOKEN", this.testToken);
      window.sessionStorage.setItem("TOKEN", this.testToken);
      window.localStorage.setItem("TOKEN", this.testToken);
    },
    // 跳转签到页
    goSign() {
      if (localStorage.getItem("nickname") == undefined || localStorage.getItem("nickname") == null
        || localStorage.getItem("headImgUrl") == undefined || localStorage.getItem("headImgUrl") == null
        || localStorage.getItem("openId") == undefined || localStorage.getItem("openId") == null
        || localStorage.getItem("openId") == "undefined") {
        Toast('未获取到微信头像或昵称，请重新登录')
        return
      }
      this.$router.push({
        path: "/sign",
        query: {
          from: "home"
        }
      });
    },
    // 跳转答题页
    goAnswer() {
      if (localStorage.getItem("nickname") == undefined || localStorage.getItem("nickname") == null
        || localStorage.getItem("headImgUrl") == undefined || localStorage.getItem("headImgUrl") == null
        || localStorage.getItem("openId") == undefined || localStorage.getItem("openId") == null || localStorage.getItem("openId") == "undefined") {
        Toast('未获取到微信头像或昵称，请重新登录')

        return
      }
      this.$router.push({
        path: "/answer",
        query: {
          from: "home"
        }
      });
    },
    // 跳转投票页
    goVote() {
      if (localStorage.getItem("nickname") == undefined || localStorage.getItem("nickname") == null
        || localStorage.getItem("headImgUrl") == undefined || localStorage.getItem("headImgUrl") == null
        || localStorage.getItem("openId") == undefined || localStorage.getItem("openId") == null || localStorage.getItem("openId") == "undefined") {
        Toast('未获取到微信头像或昵称，请重新登录')
        return
      }
      this.$router.push({
        path: "/vote",
      });
    },
    // 跳转留言页
    goMessageWall() {
      if (localStorage.getItem("nickname") == undefined || localStorage.getItem("nickname") == null
        || localStorage.getItem("headImgUrl") == undefined || localStorage.getItem("headImgUrl") == null
        || localStorage.getItem("openId") == undefined || localStorage.getItem("openId") == null || localStorage.getItem("openId") == "undefined") {
        Toast('未获取到微信头像或昵称，请重新登录')
        return
      }
      this.$router.push({
        path: "/leavingMessage",
      });
    },
  },
};
</script>
<style scoped>
.top-img {
  margin-top: 80px;
  width: 100%;
  text-align: center;
  position: relative;
}

.h-welcome {
  position: absolute;
  color: #fff;
  top: 124px;
  left: 90px;
  font-weight: bold;
  font-size: 40px;
}

.h-w-title {
  position: absolute;
  color: rgb(255, 255, 255);
  top: 174px;
  left: 90px;
  font-weight: bold;
  font-size: 50px;
}

.top-img img {
  border-radius: 40px;
  width: 94%;
  height: 340px;
}

.wisdom {
  margin: 0 auto;
  width: 94%;
  position: relative;
  height: 50px;
  margin-top: 60px;
}

.wisdom-title,
.wisdom img {
  position: absolute;
}

.wisdom img {
  width: 40px;
  height: 40px;
  left: 30px;
  top: 4px;
}

.wisdom-title {
  color: #e03838;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 600;
  left: 80px;
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.box {
  position: relative;
  margin: 0 auto;
  width: 94%;
  height: 120px;
  border-radius: 20px;
  box-shadow: 0 0 5px 5px rgb(238, 232, 232);
  margin-top: 30px;
}

.font-img,
.back-img,
.box-title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.box-title {
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 600;
  left: 91px;
  color: #656565;
}
.font-img {
  height: 41px;
  width: 40px;
  left: 30px;
}

.back-img {
  height: 40px;
  width: 40px;
  right: 50px;
}
</style>
