import axios from 'axios'

class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
    this.queue = {}
  }
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        //
      }
    }
    return config
  }
  destroy(url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      // const isToken = config.headers['X-isToken'] === false? config.headers['X-isToken'] : true
      // const token = window.localStorage.getItem('TOKEN');
      // if(token && isToken){
      //   config.headers.token = 'Bearer' + token;
      // }
      // config.headers.tenant = window.localStorage.getItem('TENANT');
      // const clientId = 'pillar';
      // const clientSecret = 'pillar_secret';
      // config.headers['Authorization'] = `Basic ${Base64.encode(`${clientId}:${clientSecret}`)}`
      // 添加全局的loading...
      
      if (!Object.keys(this.queue).length) {
        // Spin.show() // 不建议开启，因为界面不友好
      }
      this.queue[url] = true
      // if(config.method==='post'){
      //   config.headers['Content-Type']='application/json'
      //   // 请求前缀
      //   axios.defaults.baseURL = process.env.VUE_APP_API_URL;
      //   axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
        
      //   console.log(config)
      //   return config
      // }
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(url)
      const {
        data,
        status
      } = res
      return {
        data,
        status
      }
    }, error => {
      this.destroy(url)
      // addErrorLog(error.response)
      return Promise.reject(error)
    })
  }
  request(options) {
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    if(options.method == 'post') options.headers={'Content-Type' :'application/json'}
    this.interceptors(instance, options.url)
    return instance(options)
  }
}
export default HttpRequest