// 判断是否由微信浏览器打开
export const getWxNavigator = function () {
    return window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger'
}

//隐藏微信昵称
export function emoji_filter(str) {
    let new_str = ''
    let emojiReg = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;
    if (str) {
        new_str = str.replace(emojiReg, "").replace(/(\s*$)/g, "").replace(/[&nbsp;]+/g, "").trim();
    } else {
        new_str = '';
    }
    return new_str;
}

// 检测是否获取到微信头像和昵称
// export function getHN() {
//     if(localStorage.getItem("nickname")==undefined || localStorage.getItem("nickname")==null
//     || localStorage.getItem("headImgUrl")==undefined || localStorage.getItem("headImgUrl")==null){
//         return false
//     }else{
//         return true
//     }
// }