import axios from '@/utils/axios'
// import { getOpenid } from '@/api/my'

// 签到
export const userSignin = data => {
  return axios.request({
    url: '/showweixin/userSignin/add',
    data,
    method: 'post'
  })
}

// 获取微信用户信息
export const getWeChartInfo = params => {
  return axios.request({
    url: '/showweixin/showWeiXinLogin/getWeChartInfo',
    params,
    method: 'get'
  })
}

// 移动端用户留言
export const leaveComments = data => {
  return axios.request({
    url: '/showweixin/leaveComments/add',
    data,
    method: 'post'
  })
}

// 查询投票栏目
export const selectVoteDetalList = params => {
  return axios.request({
    url: '/showweixin/userVote/selectVoteDetalList',
    params,
    method: 'get'
  })
}

// 移动端用户投票
export const userVote = data => {
  return axios.request({
    url: '/showweixin/userVote/add',
    data,
    method: 'post'
  })
}

// 移动端查询最近一条投票
export const selectResultUserVote = params => {
  return axios.request({
    url: '/showweixin/userVote/selectResultUserVote',
    params,
    method: 'get'
  })
}

// 查询当天是否签到
export const selectUserSigninThatDay = data => {
  return axios.request({
    url: '/showweixin/userSignin/selectUserSigninThatDay',
    data,
    method: 'post'
  })
}

// 移动端覆盖用户签到
export const edit = data => {
  return axios.request({
    url: '/showweixin/userSignin/edit',
    data,
    method: 'post'
  })
}

// 查询活动详情
export const userSigninLoad = params => {
  return axios.request({
    url: '/showweixin/userSignin/load',
    params,
    method: 'get'
  })
}

// 查询考试详情
export const userExamLoad = params => {
  return axios.request({
    url: '/showweixin/userExam/load',
    params,
    method: 'get'
  })
}

// 移动端用户考试
export const userExamAdd = data => {
  return axios.request({
    url: '/showweixin/userExam/add',
    data,
    method: 'post'
  })
}

// 查询考试题目列表
export const searchTopicList = params => {
  return axios.request({
    url: '/showweixin/userExam/searchTopicList',
    params,
    method: 'get'
  })
}

// 移动端用户提交考试
export const addTopic = data => {
  return axios.request({
    url: '/showweixin/userExam/addTopic',
    data,
    method: 'post'
  })
}

// 查询最近一次考试记录
export const searchResultExamOne = params => {
  return axios.request({
    url: '/showweixin/userExam/searchResultExamOne',
    params,
    method: 'get'
  })
}

// 查询考试已答题目列表
export const searchResultExamList = params => {
  return axios.request({
    url: '/showweixin/userExam/searchResultExamList',
    params,
    method: 'get'
  })
}

// 查询投票详情
export const searchVoteInfo = params => {
  return axios.request({
    url: '/showweixin/userVote/searchVoteInfo',
    params,
    method: 'get'
  })
}

// 生成调用扫一扫需要的签名
export const getWxConfigs = params => {
  return axios.request({
    url: '/showweixin/showWeiXinLogin/getWxConfigs',
    params,
    method: 'get'
  })
}

// 课程签到
export const studySigninAdd = data => {
  return axios.request({
    url: '/showweixin/userSignin/studySigninAdd',
    data,
    method: 'post'
  })
}

// 获取课程名
export const categoryLoad = params => {
  return axios.request({
    url: '/showweixin/userSignin/categoryLoad',
    params,
    method: 'get'
  })
}