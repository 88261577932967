<template>
<!-- 组件：底部 tabBar 标签栏 -->
  <div>
    <router-view />
    <van-tabbar v-model="active">
      <van-tabbar-item
        v-for="(item, index) in tabbars"
        :key="index"
        :to="item.name"
      >
        <span>{{ item.title }}</span>
        <img
          slot="icon"
          slot-scope="props"
          :src="props.active ? item.active : item.normal"
        />
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "tabbar",
  data() {
    return {
      active: 0,
      tabbars: [
        {
          name: "/",
          title: "首页",
          normal: require("../assets/img/home-gray.png"),
          active: require("../assets/img/home-red.png"),
        },
        {
          name: "Persion",
          title: "我的",
          normal: require("../assets/img/persion-gray.png"),
          active: require("../assets/img/persion-red.png"),
        },
      ],
    };
  },
  methods: {},
  // 通过路由跳转判断选中的样式
  created() {
    if (this.$route.name == "/") {
      this.active = 0;
    } else if (this.$route.name == "Persion") {
      this.active = 1;
    }
  },
};
</script>

<style scoped>
.van-tabbar {
  border-top: 1px solid #f5f5f5;
}
</style>
